import {
  Box,
  BoxProps,
  Container,
  Flex,
  Heading,
  HeadingProps,
  Link,
  LinkProps,
  SimpleGrid,
  Text,
  TextProps,
} from '@chakra-ui/react';
import BPCPageHeading from 'common/components/BPCPageHeading';
import {
  BPCLogoWithName,
  NAVBAR_HEIGHT,
} from 'router/layouts/ConnectedLayoutHeader';
import Markdown from 'react-markdown';

const MarkdownH2 = (props: HeadingProps) => (
  <Heading mt={5} as="h2" size="md" {...props} />
);

const MarkdownText = (props: TextProps) => <Text my={1} {...props} />;

const MarkdownLink = (props: LinkProps) => (
  <Link color={'orange.500'} fontWeight={'bold'} {...props} />
);

const MarkdownStrong = (props: BoxProps) => (
  <Box as="span" color={'orange.500'} fontWeight={'bold'} {...props} />
);

export default function StatutsPage() {
  return (
    <Box>
      <SimpleGrid
        bg="white"
        px={5}
        columns={1}
        height={NAVBAR_HEIGHT}
        borderBottomColor={'gray.200'}
        borderBottomWidth={'1px'}
      >
        <Flex alignItems={'center'} justifyContent={'center'}>
          <BPCLogoWithName />
        </Flex>
      </SimpleGrid>
      <Container
        maxW="container.lg"
        overflowX={'scroll'}
        height={`calc(100vh - ${NAVBAR_HEIGHT})`}
        mb={8}
        pt={5}
      >
        <BPCPageHeading
          helmetName="Statuts BPC"
          title="Statuts du Belloin Poker Club (BPC)"
        />
        <Markdown
          components={{
            h2: MarkdownH2,
            p: MarkdownText,
            a: MarkdownLink,
            strong: MarkdownStrong,
          }}
        >
          {`
## Article 1 : Nom et Objet de l'Association
Le **Belloin Poker Club** (BPC) est une association de parents joueurs de poker ne pouvant faire autrement que de se réunir la nuit tombée une fois les enfants couchés.

## Article 2 : Siège Social
Le siège social du BPC est situé au rez de chaussée du domicile des membres fondateurs.\n
Les parties se jouent généralement au sein du siège social mais elles peuvent être délocalisées temporairement par décision collective des membres actifs.

## Article 3 : Membres
Le BPC est ouvert à tous les amateurs de poker, sous réserve d'une validation de sa présidente et de la lecture du livre: "Si t'y vas pas maintenant t'iras jamais" par N. Fontana (IBAN toujours en attente).

## Article 4 : Inscriptions et Horaires
Les tournois du BPC débutent à **21h30 précises** (sauf en cas de retard du vice-président, auquel cas il s'agit d'un délai "stratégique").\n
Les membres doivent s'inscrire avant **21h20** faute de quoi ils seront gentiment invités à observer la partie en tant que "conseillers stratégiques non sollicités".

## Article 5 : Une fois, mais pas deux !
Chaque joueur dispose d'**une seule recave** par soirée. Une fois qu'elle est utilisée, il n'est plus possible de racheter des jetons supplémentaires.\n
Exception spéciale "Joyeux Anniversaire" : Si le joueur fête son anniversaire dans les **7 jours précédant le tournoi**, il bénéficie de **recaves illimitées** pour cette soirée.\n
En cas de doute sur la date d'anniversaire, une vérification simple sera effectuée et le bénéfice de l'exception sera accordé sur présentation d'une preuve.

## Article 6 : L'information est publique
Les résultats des tournois seront compilés pour établir un classement général des membres du BPC. Voir le site [poker.belloin.fr](https://poker.belloin.fr)

## Article 7 : Code Vestimentaire
Aucune tenue spécifique n'est exigée, mais il est recommandé de porter une tenue confortable et adaptée à la durée des parties de poker.\n
Les accessoires de poker (lunettes de soleil, casquettes) sont autorisés.

## Article 8 : Dissolution de l'Association
En cas de dissolution du BPC (après une série de bad beats insoutenables), bah fallait y reflechir avant !

## Article 9 : Tu connais la règle
Si t'as une paire méfie toi.

## Article 10 : Jurisprudence Limoncello
En cas d'apport de Limoncello à la table, le joueur doit en informer les autres joueurs au moins 30 minutes à l'avance afin que chacun puisse pendre ses dispositions.\n
Les règles de recaves listées plus haut restent valables même en cas de consommation de Limoncello.

## Article 11 : Je salis le tapis, je nettoie
Chaque joueur est responsable du bon entretien de son espace de jeu.\n
En cas de tâche sur le tapis, le joueur responsable doit nettoyer immédiatement la zone concernée et si nécessaire prendre les dispositions adéquates pour le néttoyage complet du tapis.
      `}
        </Markdown>
      </Container>
    </Box>
  );
}

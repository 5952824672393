import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  SimpleGrid,
  StackDivider,
  VStack,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { AjouterKillValues, TournoiPlayer } from '../tournoi.types';
import { memo, useMemo } from 'react';
import { BPCInput } from 'common/components/form/BPCInput';
import TournoiPlayerAvatar from 'features/players/components/TournoiPlayerAvatar';

function computeNbRecavesMax(
  playersInGame: TournoiPlayer[],
  victimeId: string,
  nbRecavesMax: number,
) {
  return (
    nbRecavesMax -
    (playersInGame.find(({ id }) => id === victimeId)?.nbRecave ?? 0)
  );
}

function AjouterKillForm({
  players,
  nbRecavesMax,
  errorMessage,
  isLoading,
  onClose,
  withHourSelect = true,
}: Readonly<{
  players: TournoiPlayer[];
  nbRecavesMax: number;
  errorMessage?: string;
  isLoading: boolean;
  onClose: () => void;
  withHourSelect?: boolean;
}>) {
  const playersInGame = useMemo(
    () => players.filter(({ position }) => !position),
    [players],
  );
  const { values, errors, touched, setFieldValue, handleSubmit } =
    useFormikContext<AjouterKillValues>();
  const possibleKillers = playersInGame.filter(
    ({ id }) => id !== values.victimeId,
  );

  const nbRecaveRestante = values.victimeId
    ? computeNbRecavesMax(playersInGame, values.victimeId, nbRecavesMax)
    : undefined;

  const victime = playersInGame.find(({ id }) => id === values.victimeId);
  return (
    <form onSubmit={handleSubmit}>
      <ModalOverlay />
      <ModalContent>
        {/* V2 ajouter des textes random */}
        <ModalHeader>Ajout d'un kill</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {withHourSelect && (
            <BPCInput
              type="time"
              fieldName="heure"
              label="Heure de l'élimination"
            />
          )}
          <VStack spacing={6} divider={<StackDivider />} align="strech">
            {errorMessage ? (
              <Alert status="error" mb={3}>
                <AlertIcon />
                <AlertDescription>{errorMessage}</AlertDescription>
              </Alert>
            ) : undefined}
            <FormControl
              isRequired
              isInvalid={Boolean(!!errors.victimeId && touched.victimeId)}
            >
              <FormLabel htmlFor="victimeId">☠️ Qui est la victime ?</FormLabel>
              <RadioGroup
                id="victimeId"
                value={values.victimeId ?? undefined}
                onChange={(val) => {
                  setFieldValue('victimeId', val);
                  setFieldValue(
                    'isRecave',
                    computeNbRecavesMax(playersInGame, val, nbRecavesMax) > 0,
                  );
                }}
              >
                <SimpleGrid columns={3} gap={2}>
                  {playersInGame.map((p) => (
                    <Radio colorScheme="orange" key={p.id} value={p.id}>
                      <TournoiPlayerAvatar player={p} namePosition="right" />
                    </Radio>
                  ))}
                </SimpleGrid>
              </RadioGroup>
              <FormErrorMessage>{errors.victimeId}</FormErrorMessage>
            </FormControl>
            {values.victimeId && nbRecaveRestante !== undefined && (
              <FormControl>
                <RadioGroup
                  key={`recave-${values.victimeId}`}
                  id="isRecave"
                  value={values.isRecave ? 'true' : 'false'}
                  onChange={(val) =>
                    setFieldValue('isRecave', val === 'true' ? true : false)
                  }
                >
                  <SimpleGrid columns={3} gap={2}>
                    <FormLabel m={0} htmlFor="isRecave">
                      {`💸 ${victime?.name}, tu recaves ?`}
                    </FormLabel>
                    <Radio colorScheme="orange" value={'true'}>
                      Oui
                    </Radio>
                    <Radio colorScheme="orange" value={'false'}>
                      Non
                    </Radio>
                  </SimpleGrid>
                  <FormHelperText>
                    {nbRecaveRestante > 0
                      ? `Encore ${nbRecaveRestante} possible${
                          nbRecaveRestante > 1 ? 's' : ''
                        }, allez fais toi plez !`
                      : nbRecaveRestante === 0
                      ? `Déjà toutes utilisées, c'est fini pour aujourd'hui`
                      : `Déjà ${Math.abs(
                          nbRecaveRestante,
                        )} de trop, faut arrêter les abus là !`}
                  </FormHelperText>
                </RadioGroup>
                <FormErrorMessage>{errors.killerId}</FormErrorMessage>
              </FormControl>
            )}
            {values.victimeId && (
              <FormControl
                isRequired
                isInvalid={Boolean(!!errors.killerId && touched.killerId)}
              >
                <FormLabel htmlFor="killerId">🔫 Qui est le killer ?</FormLabel>
                <RadioGroup
                  id="killerId"
                  value={values.killerId ?? undefined}
                  onChange={(val) => setFieldValue('killerId', val)}
                >
                  <SimpleGrid columns={3} gap={2}>
                    {possibleKillers.map((p) => (
                      <Radio colorScheme="orange" key={p.id} value={p.id}>
                        <TournoiPlayerAvatar player={p} namePosition="right" />
                      </Radio>
                    ))}
                  </SimpleGrid>
                </RadioGroup>
                <FormErrorMessage>{errors.killerId}</FormErrorMessage>
              </FormControl>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Annuler
          </Button>
          <Button isLoading={isLoading} type="submit">
            Valider
          </Button>
        </ModalFooter>
      </ModalContent>
    </form>
  );
}

// AjouterKillForm.whyDidYouRender = {
//   logOnDifferentValues: true,
//   customName: 'Form',
// };

export default memo(AjouterKillForm);

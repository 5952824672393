import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from 'react-router-dom';
import { RoutePath } from './routes.paths';
import LoginPage from './routes/LoginPage';
import SignUpPage from './routes/SignUpPage';
import ConnectedLayout from './layouts/ConnectedLayout';
import useFirebaseAuthentication from 'features/auth/useFirebaseAuthentication';
import { User } from 'firebase/auth';

import ErrorPage, { NotFound } from './routes/ErrorPage';
import Loader from 'common/components/Loader';
import HomePage from './routes/HomePage';
import dayjs from 'dayjs';
import EditIncompleteProfilePage from './routes/EditIncompleteProfilePage';
import NouveauTournoiPage from './routes/NouveauTournoiPage';
import { setDefaultOptions } from 'date-fns';
import { fr } from 'date-fns/locale';
import 'dayjs/locale/fr';
import EditProfilePage from './routes/EditIProfilePage';
import ChampionnatsListPage from './routes/ChampionnatsListPage';
import NouveauChampionnatPage from './routes/NouveauChampionnatPage';
import ChampionnatsDetailPage from './routes/ChampionnatDetailPage';
import TournoiDetailPage from './routes/TournoiDetailPage';
import OutilFormulePage from './routes/OutilsFormulePage';
import OutilRangesPage from './routes/OutilsRangesPage';
import EditionChampionnatPage from './routes/EditionChampionnatPage';
import OutilsPage from './routes/OutilsPage';
import EditionTournoiPage from './routes/EditionTournoiPage';
// import BandeauDev from './layouts/BandeauDev';
import JoueursListPage from './routes/JoueursListPage';
import EditionJoueurPage from './routes/EditionJoueurPage';
import TournoiLivePage from './routes/TournoiLivePage';
import ConnectedRoute from './layouts/ConnectedRoute';
import { TesterSonsPage } from './routes/TesterSonsPage';
import ChampionnatJoueurPage from './routes/ChampionnatJoueurPage';
import OutilNouvelleRangePage from './routes/OutilsNouvelleRangePage';
import OutilRangeDetailPage from './routes/OutilsRangeDetailPage';
import OutilEditionRangePage from './routes/OutilsEditionRangePage';
import NouveauTournoiImportPage from './routes/NouveauTournoiImportPage';
import EditionTournoiImportPage from './routes/EditionTournoiImportPage';
import TournoiImportDetailPage from './routes/TournoiImportDetailPage';
import StatutsPage from './routes/StatutsPage';

// Redirige l'utilisateur vers la connexion si il n'est pas connecté
const ProtectedRoute = ({
  firebaseAuthUser,
  redirectPath = RoutePath.login,
}: {
  firebaseAuthUser?: User;
  redirectPath?: string | RoutePath;
}) => {
  if (!firebaseAuthUser) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

// Redirige l'utilisateur vers la home si il est déjà connecté (pas d'accès aux pages de login/inscription)
const AuthRoute = ({
  firebaseAuthUser,
  redirectPath = RoutePath.home,
}: {
  firebaseAuthUser?: User;
  redirectPath?: string | RoutePath;
}) => {
  if (firebaseAuthUser) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

// Besoin de ce wrapper pour que useFirebaseAuthentication soit dans le contexte du router et qu'on puisse utiliser le hook useNavigate()
export default function BPCRouterWrapper() {
  dayjs.locale('fr');
  setDefaultOptions({ locale: fr });
  return (
    <BrowserRouter>
      <BPCRouter />
    </BrowserRouter>
  );
}

// Dimension de l'ipad: 998 x 700
function BPCRouter() {
  const { firebaseAuthUser, isFirstLoading } = useFirebaseAuthentication();
  if (isFirstLoading) {
    return <Loader fullScreen />;
  }

  return (
    <Routes>
      {/* <Route element={<BandeauDev />}> */}
      <Route path="*" element={<NotFound />} />
      <Route
        errorElement={<ErrorPage />}
        path="/"
        element={<Navigate to={RoutePath.login} replace />}
      />
      <Route path="/statuts" element={<StatutsPage />} />
      <Route element={<AuthRoute firebaseAuthUser={firebaseAuthUser} />}>
        <Route path={RoutePath.login} element={<LoginPage />} />
        <Route path={RoutePath.signUp} element={<SignUpPage />} />
      </Route>
      <Route element={<ProtectedRoute firebaseAuthUser={firebaseAuthUser} />}>
        {/* Cette route ne peut pas être sous la ConnectedRoute sinon on aurait un loader infini */}
        <Route
          path={RoutePath.editIncompleteProfile}
          element={<EditIncompleteProfilePage />}
        />
        <Route element={<ConnectedRoute />}>
          <Route element={<ConnectedLayout />}>
            <Route path={RoutePath.home} element={<HomePage />} />
            <Route path={RoutePath.editProfile} element={<EditProfilePage />} />
            <Route path={RoutePath.outils}>
              <Route index element={<OutilsPage />} />
              <Route path="formule" element={<OutilFormulePage />} />
              <Route path="ranges">
                <Route index element={<OutilRangesPage />} />
                <Route path="creation" element={<OutilNouvelleRangePage />} />
                <Route path=":id">
                  <Route index element={<OutilRangeDetailPage />} />
                  <Route path="edition" element={<OutilEditionRangePage />} />
                </Route>
              </Route>
            </Route>
            <Route path={RoutePath.championnats}>
              <Route index element={<ChampionnatsListPage />} />
              <Route path="creation" element={<NouveauChampionnatPage />} />
              <Route path=":id">
                <Route index element={<ChampionnatsDetailPage />} />
                <Route path="edition" element={<EditionChampionnatPage />} />
                <Route path="joueurs">
                  <Route path=":joueurId">
                    <Route index element={<ChampionnatJoueurPage />} />
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path={RoutePath.tournois}>
              <Route index element={<Navigate to={RoutePath.home} replace />} />
              <Route path="creation" element={<NouveauTournoiPage />} />
              <Route path=":id">
                <Route index element={<TournoiDetailPage />} />
                <Route path="edition" element={<EditionTournoiPage />} />
              </Route>
              <Route path="import">
                <Route path="creation" element={<NouveauTournoiImportPage />} />
                <Route path=":id">
                  <Route index element={<TournoiImportDetailPage />} />
                  <Route
                    path="edition"
                    element={<EditionTournoiImportPage />}
                  />
                </Route>
              </Route>
            </Route>
            <Route path={RoutePath.joueurs}>
              <Route index element={<JoueursListPage />} />
              <Route path=":id">
                {/* <Route index element={<JoueurDetailPage />} /> */}
                <Route path="edition" element={<EditionJoueurPage />} />
              </Route>
            </Route>
            <Route path="/sons" element={<TesterSonsPage />} />
          </Route>
          <Route
            path={`${RoutePath.tournois}/:id/live`}
            element={<TournoiLivePage />}
          />
        </Route>
      </Route>
      {/* </Route> */}
    </Routes>
  );
}
